<template>
  <div class="page" v-loading="pageLoading">
    <div class="container">
      <div class="header">
        <div class="icon"></div>
        <div class="title">{{ $t('meetingPage.title') }}</div>
      </div>
      <div class="content">
        <div>
          <el-table
            class="table"
            :data="tableData"
            v-loading="listLoading"
            header-cell-class-name="header"
            style="width: 100%"
          >
            <el-table-column prop="date" label="序号" align="left" width="60">
              <template v-slot="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column prop="subject" label="会议主题" align="left">
            </el-table-column>
            <!-- <el-table-column prop="meetingId" label="会议Id" align="left">
            </el-table-column> -->
            <el-table-column prop="meetingCode" label="会议号" align="left">
            </el-table-column>
            <el-table-column prop="startTime" width="180" label="开始时间" align="left">
              <template v-slot="scope">
                {{ moment.unix(Number(scope.row.startTime)).format('YYYY-MM-DD HH:mm:ss') }}
              </template>
            </el-table-column>
            <el-table-column prop="endTime"  width="180" label="结束时间" align="left">
              <template v-slot="scope">
                {{ moment.unix(Number(scope.row.endTime)).format('YYYY-MM-DD HH:mm:ss') }}
              </template>
            </el-table-column>
            <el-table-column v-if="searchForm.meetingStatus == 1" prop="joinUrl" label="入会链接" align="left">
              <template v-slot="scope">
                <el-button style="margin-right:5px;" type="primary" link @click="handleCopy(scope.row.joinUrl)">复制链接</el-button>
                <span class="link-url" @click="handleOpen(scope.row.joinUrl)"> {{ scope.row.joinUrl }}</span>
              </template>
            </el-table-column>
          </el-table>
          <common-page
            v-show="this.searchForm.meetingStatus == 2"
            v-model:current-page="pageObj.page"
            v-model:page-size="pageObj.size"
            :total="pageObj.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </common-page>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import commonPage from '@/components/public/pagination.vue'
import simplePage from '@/components/public/simple-page.vue'
import { getMyMeetingList, meetingListClose, addMeeting, editMeeting, delMeeting, hostList, meetingDetail, userList } from '@/api/api'
import moment from 'moment'
import { handleCopy } from '@/utils/utils'
export default {
  components: {
    commonPage
    // simplePage
  },
  data () {
    return {
      moment: moment,
      hostList: [],
      tableData: [],
      options: [],
      pageObj: {
        page: 1,
        size: 10,
        total: 1000
      },
      createDialog: {
        title: '创建会议',
        show: false,
        // 是否编辑状态
        isEdit: false
      },
      createForm: {
        subject: '',
        userId: '',
        dateRange: null,
        room: false,
        guest: ''
      },
      createRules: {
        subject: [
          { required: true, message: '必填项不能为空', trigger: 'blur' }
        ],
        userId: [
          { required: true, message: '必填项不能为空', trigger: 'blur' }
        ],
        dateRange: [
          { required: true, message: '必填项不能为空', trigger: 'blur' }
        ]
      },
      searchForm: {
        userId: '',
        meetingStatus: 1
      },
      submitLoading: false,
      listLoading: false,
      pageLoading: false,
      tabList: [
        { id: 1, name: '进行中/即将召开' },
        { id: 2, name: '已结束的会议' }
      ],
      props: {
        label: 'name',
        children: 'children',
        isLeaf: 'isLeaf'
      },
      cacheData: [],
      treeId: 0,
      guestDialog: {
        show: false
      },
      treeLoading: false
    }
  },
  created () {
    this.getMyMeetingList()
  },
  methods: {
    // handleTabClick () {},
    handleCurrentChange (val) {
      this.pageObj.page = val
      this.getMyMeetingList()
    },
    handleSizeChange (val) {
      this.pageObj.size = val
      this.pageObj.page = 1
      this.getMyMeetingList()
    },
    getMyMeetingList () {
      this.tableData = []
      this.listLoading = true
      let userInfo = localStorage.getItem('userInfo')
      userInfo = JSON.parse(userInfo)
      const params = {
        userId: userInfo.login_account
      }
      getMyMeetingList(params).then(res => {
        if (res.code === 0) {
          this.tableData = res.data
        }
        this.listLoading = false
      }).catch(() => {
        this.listLoading = false
      })
    },
    handleCopy (text) {
      handleCopy(text)
    },
    handleOpen (url) {
      if (!url) return
      window.open(url)
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
  margin: 0 auto;
  width: 1200px;
  background: white;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  padding: 20px;
  margin-top: 20px;
}
.header {
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: 20px;
  .icon {
    width: 6px;
    height: 30px;
    background: $colorGreen;
    margin-right: 10px;
  }
  .title {
    line-height: 40px;
    font-size: 18px;
    font-weight: bold;
  }
  .search {
    margin-left: auto;
  }
}
.tabs {
  display: flex;
  height: 40px;
  color: black;
  font-size: 18px;
  .tab-item {
    height: 40px;
    line-height: 40px;
    margin-right: 40px;
    letter-spacing: 1px;
    cursor: pointer;
    &:last-child {
      margin-right: 0px;
    }
    &:hover {
      color: $colorGreen;
    }
  }
  .active {
    color: $colorGreen;
    font-weight: 500;
    border-bottom: 3px solid $colorGreen;
  }
}
.link-url {
  cursor: pointer;
  &:hover {
    color: $colorBlue;
    text-decoration: underline;
  }
}
</style>
